import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import Layout from '../components/layout'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import CtaBar from '../components/cta-bar'
import Testimonials from '../components/testimonials'
import Team from '../components/team'
import VictorP from '../assets/images/VictorP.png'
import Clients from '../components/clients'
import Button from 'react-bootstrap/Button'
import { Breadcrumb } from 'gatsby-plugin-breadcrumb'




class AboutPage extends React.Component {
  render() {
    const testimonials = get(this, 'props.data.allContentfulTestimonial.edges')
    const team = get(this, 'props.data.allContentfulTeam.edges')
    const body = get(this, 'props.data.allContentfulPageContent.edges[0].node')
    const jobLink = get(this, 'props.data.allContentfulJobListingLink.edges[0].node.link')
    const location = this.props.location;

    const ceo = team.find(element => element.node.id === "2b683db2-a151-5314-8b90-cc9cd87b4b5f");
    return (
      <Layout location={this.props.location}>
        <div className="inner-page-about">
        <Container>
          <Breadcrumb location={location} crumbLabel="About" />
        </Container>
          <Row className="inner-page-banner about-banner">
            <Col>
              <h2>ABOUT US</h2>
              <h1>Our Team</h1>
              <a href={jobLink} target="_new"> <Button className="cta-button-banner">JOB LISTINGS</Button></a>
            </Col>
          </Row>
          <Container>
            <Row className="space">
              <Col>
                <h2 className="green-headline">{body.title}</h2>
                <p>{body.body.body}</p>
              </Col>
            </Row>
          </Container>
          <Row id="face-row">
            <Col>
              <Container>
                <Row className="space">
                  <Col>
                    <div class="green-circle">
                      <span>
                        F
                  </span>
                    </div>
                    <h3>Focus</h3>
                  </Col>
                  <Col>
                    <div class="green-circle">
                      <span>
                        A
                  </span>
                    </div>
                    <h3>Accountability</h3>
                  </Col>
                  <Col>
                    <div class="green-circle">
                      <span>
                        C
                  </span>
                    </div>
                    <h3>Commitment</h3>
                  </Col>
                  <Col>
                    <div class="green-circle">
                      <span>
                        E
                  </span>
                    </div>
                    <h3>Execution</h3>
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
            <Row className="space">
              <Col md="4" className="my-auto">
                <img style={{ maxWidth: '100%' }} src={ceo.node.image.file.url} />
              </Col>
              <Col className="my-auto ceo-bio" md="8" >
                <p className="team-member-title">{ceo.node.title}</p>
                <h2 className="team-member-name">{ceo.node.name}</h2>
                <p className="team-member-bio">
              {ceo.node.bio.bio}</p>
              </Col>
            </Row>

          <CtaBar />
          <Container>
          <Row className="space" style={{marginBottom:'0', paddingBottom:'0'}}>
              <Col>
                <h2 className="green-headline" style={{ textAlign: 'left', marginBottom:'0' }}>Meet The Team</h2>
              </Col>
            </Row>
          <Team team={team} />
          </Container>
          <Testimonials testimonials={testimonials} />
          <Clients />
        </div>
      </Layout>
    )
  }
}

export default AboutPage

export const pageQuery = graphql`
  query AboutQuery {
    allContentfulPageContent(filter: {node_locale: {eq: "en-US"}, id: {eq: "1ead9d03-2c68-54f5-a53e-68ae8ba4fc41"}}) {
      edges {
        node {
          title
          body {
            body
          }
          id
        }
      }
    }
    allContentfulTeam(sort: {fields: order, order: ASC}, filter: {node_locale: {eq: "en-US"}}) {
      edges {
        node {
          bio {
            bio
          }
          id
          name
          image {
            file {
              url
            }
          }
          title
        }
      }
    }
    allContentfulTestimonial(filter: {node_locale: {eq: "en-US"}}) {
      edges {
        node {
          name
          title
          quote
          image {
            file {
              url
            }
          }
        }
      }
    }
    allContentfulJobListingLink {
      edges {
        node {
          link
        }
      }
    }
  }
`
