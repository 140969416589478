import React from 'react'

import Container from 'react-bootstrap/Container'
import TeamMember from './team-member'

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


const Team = props => {

    const { team } = props



    return (
        <div id="team-carousel">
            <Container>
                    {team.length > 0 ? team.map( person => {
                        if(person.node.id != '2b683db2-a151-5314-8b90-cc9cd87b4b5f') {
                        return(
                            <TeamMember person={person}/>
                            
                        )
                        }
                    }) : <p>No Team Members</p>}
            </Container>
        </div>

    )
}

export default Team


