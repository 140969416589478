import React, { useState } from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'


const TeamMember = props => {

    const { person } = props
    const name = person.node.name
    const title = person.node.title
    const image = person.node.image.file.url
    const bio = person.node.bio.bio
    return (
        <Row className="space">
        <Col md="4">
                <img className="team-member-image" src={image} />
            </Col>
            <Col md="8">
                <p className="team-member-title">{title}</p>
                <h3 className="team-member-name">{name}</h3>
                <p className="team-member-bio">
                    {bio}
                </p>
            </Col>
        </Row>
    )
}

export default TeamMember